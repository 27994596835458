
import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { hotelItemTypes } from './helpers/shared'
@Component({})
export default class AvailabilityEditDialog extends Vue {
  //Props
  @Prop({ required: true, type: Array, default: [] })
  eventsInEdit!: any[]
  @Prop()
  value!: boolean

  // data // ***
  availabilityType = 2
  loading = false
  events: any[] = []

  // computed // ***
  get dateInEdit(): string {
    if (this.eventsInEdit.length == 0) return 'Unknown'
    const dateString = DateTime.fromISO(this.eventsInEdit[0].start).toFormat('EEE, dd MMM, yyyy')
    return dateString
  }
  //Watch
  @Watch('value')
  onValueChanged(v: boolean) {
    if (v) this.setEvents()
  }

  setEvents() {
    this.events = cloneDeep(this.eventsInEdit)
  }
  getEventTitle(event: Record<string, any>): string {
    let item = hotelItemTypes.find(x => x.id === event.itemTypeId)
    const roomName = item
      ? this.$tc(`availability.${item.key}${event.numberAvailable === 1 ? `` : `s`}`)
      : 'NOT FOUND'
    return `${event.numberAvailable} x ${roomName}${
      event.numberAvailable > 0 ? ` @ ${event.rate}` : ''
    }${event.customerName ? ` for ${event.customerName}` : ` General Availability`}`
  }
  hasCustomerName(event: Record<string, any>): boolean {
    if (event.customerName) {
      return true
    }
    return false
  }
  close() {
    this.$emit('input', false)
  }
  onCancel() {
    this.close()
  }
  onSubmit() {
    this.$emit('submit', this.events)
  }
}
