
import { getCurrency } from '@/utilities/views'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { hotelItemTypes } from './helpers/shared'

@Component({})
export default class AvailabilityInputForm extends Vue {
  //Props
  @Prop({ required: true, type: Number })
  availabilityType!: number
  @Prop({ required: true, type: Array, default: [] })
  itemTypeIds!: number[]
  @Prop({ required: false, default: false })
  hasRates!: boolean
  @Prop({ required: true })
  loading!: boolean
  @Prop({ required: true })
  disabled!: boolean
  @Prop({ required: false, default: false })
  rounded!: boolean
  @Prop({ required: false, default: 0 })
  elevation!: number | string
  @Prop({ required: false, default: [] })
  rates!: Record<string, any>[]
  useCmacRates = true
  numberAvailable = 0
  rate = null

  // computed // ***
  get currencySymbol() {
    return getCurrency(this.$auth.activeUser.currency)
  }

  //Methods
  getItemName(itemTypeId: number) {
    let item = hotelItemTypes.find(x => x.id === itemTypeId)
    return item ? this.$tc(`availability.${item.key}`) : 'NOT FOUND'
  }
  submit() {
    this.$emit('submit', {
      itemTypes: [{ id: 1, numberAvailable: this.numberAvailable, rate: this.rate }],
      useCmacRates: this.hasRates ? this.useCmacRates : false,
    })
  }
  hasRoomRate(roomTypeId: number) {
    if (!this.hasRates || !this.useCmacRates) return true
    return this.rates[0].organisationHotelRateCardEntries.some(
      (x: { hotelRequirementTypeId: number; weekendRate: number; weekdayRate: number }) =>
        x.hotelRequirementTypeId == roomTypeId && x.weekendRate && x.weekdayRate
    )
  }
  onCancel() {
    this.$emit('cancel')
  }
}
