
import api from '@/utilities/api'
import { DateTime, Interval } from 'luxon'
import { Component, Vue } from 'vue-property-decorator'
import AvailabilityEditDialog from './AvailabilityEditDialog.vue'
import AvailabilityInputForm from './AvailabilityInputForm.vue'
import BundleEditDialog from './BundleEditDialog.vue'
import { ItemType, hotelItemTypes } from './helpers/shared'

@Component({
  components: {
    'c-availability-form': AvailabilityInputForm,
    'c-availability-edit-modal': AvailabilityEditDialog,
    'c-bundle-edit-modal': BundleEditDialog,
  },
})
export default class Availability extends Vue {
  // data // ***
  value: string | null = null
  availabilityType = 2
  loading = false
  editing = false
  editingBundle = false
  availabilityInEdit: Record<string, any> = {}
  selectedDates: string[] = []
  existingAvailability: any[] = []
  weekdayToggles = [
    { nameKey: 'sunday', value: true },
    { nameKey: 'monday', value: true },
    { nameKey: 'tuesday', value: true },
    { nameKey: 'wednesday', value: true },
    { nameKey: 'thursday', value: true },
    { nameKey: 'friday', value: true },
    { nameKey: 'saturday', value: true },
  ]
  rates: Record<string, any>[] = []
  editDate = ''
  bundlePriceInEdit: Record<string, any> = {}
  isAdding = false
  bundlePrices: Record<string, any>[] = []
  file: File = new File([], '')
  // computed // ***
  get events(): any[] {
    let newAvailability = this.selectedDates.map(x => {
      return {
        name: x,
        start: x,
        color: 'red',
        timed: false,
        temp: true,
        createdText: null,
        updatedText: null,
      }
    })
    return [...newAvailability, ...this.existing]
  }
  get existing(): any[] {
    const { existingAvailability, bundlePrices } = this
    const rates = existingAvailability.map(e => {
      return {
        name: this.getEventTitle(e),
        start: e.validFrom.split('T')[0],
        timed: false,
        id: e.id,
        itemTypeId: e.itemTypeId,
        numberAvailable: e.numberAvailable,
        rate: e.rate,
        priceDoubleRoom: null,
        priceBundle4: null,
        priceBundle8: null,
        priceBundle15: null,
        createdText: this.getCreatedText(e),
        updatedText: this.getUpdatedText(e),
        customerName: this.getCustomerText(e),
      }
    })

    if (!this.hasSleepParkFlyFeature) return rates

    if (this.hasSleepParkFlyRoomOnlyFeature) {
      bundlePrices.forEach(x => {
        rates.push({
          name: x.priceDoubleRoom ? x.priceDoubleRoom : 'Closed',
          start: DateTime.fromISO(x.date).toISODate(),
          timed: false,
          id: `${x.date}-room`,
          itemTypeId: -1,
          numberAvailable: 1,
          rate: x.priceDoubleRoom,
          priceDoubleRoom: x.priceDoubleRoom,
          priceBundle4: null,
          priceBundle8: null,
          priceBundle15: null,
          createdText: '',
          updatedText: '',
          customerName: '',
        })
      })
    }

    if (this.hasSleepParkFlyBundleFeature) {
      bundlePrices.forEach(x => {
        rates.push({
          name: x.priceBundle4 ? x.priceBundle4 : 'Closed',
          start: DateTime.fromISO(x.date).toISODate(),
          timed: false,
          id: `${x.date}-bundle4`,
          itemTypeId: -2,
          numberAvailable: 1,
          rate: x.priceBundle4,
          priceDoubleRoom: null,
          priceBundle4: x.priceBundle4,
          priceBundle8: null,
          priceBundle15: null,
          createdText: '',
          updatedText: '',
          customerName: '',
        })
        rates.push({
          name: x.priceBundle8 ? x.priceBundle8 : 'Closed',
          start: DateTime.fromISO(x.date).toISODate(),
          timed: false,
          id: `${x.date}-bundle8`,
          itemTypeId: -3,
          numberAvailable: 1,
          rate: x.priceBundle8,
          priceDoubleRoom: null,
          priceBundle4: null,
          priceBundle8: x.priceBundle8,
          priceBundle15: null,
          createdText: '',
          updatedText: '',
          customerName: '',
        })
        rates.push({
          name: x.priceBundle15 ? x.priceBundle15 : 'Closed',
          start: DateTime.fromISO(x.date).toISODate(),
          timed: false,
          id: `${x.date}-bundle15`,
          itemTypeId: -4,
          numberAvailable: 1,
          rate: x.priceBundle15,
          priceDoubleRoom: null,
          priceBundle4: null,
          priceBundle8: null,
          priceBundle15: x.priceBundle15,
          createdText: '',
          updatedText: '',
          customerName: '',
        })
      })
    }

    return rates
  }
  get itemTypeIds(): number[] {
    return hotelItemTypes.map(x => x.id)
  }
  get currentCalendarView(): any {
    return this.value ? DateTime.fromISO(this.value) : DateTime.local()
  }
  get monthName(): string {
    return this.$tc(`datetime.${this.currentCalendarView.monthLong.toLowerCase()}`)
  }
  get year(): string {
    return this.currentCalendarView.year
  }
  get supplier(): Record<string, any> {
    return this.$store.getters['suppliers/selectedSupplier']
  }
  get organisationId(): string {
    return String(this.supplier?.id)
  }
  get organisationName(): string {
    return String(this.supplier?.name)
  }
  get eventsInEdit(): any[] {
    return this.existing.filter(x => x.start === this.editDate && x.itemTypeId > 0)
  }
  get editTitle(): string {
    const dateString = DateTime.fromISO(this.availabilityInEdit.validFrom).toFormat(
      'EEE, dd MMM, yyyy'
    )
    return `${dateString} - ${this.getEventTitle(this.availabilityInEdit)}`
  }
  get isInSelectionMode(): boolean {
    return this.selectedDates.length > 0
  }
  get hasRates() {
    return this.rates.length > 0 && this.rates.every(x => x?.submittedBySummary ?? false)
  }
  get features(): Record<string, any> {
    return this.$auth.features
  }
  get hasSleepParkFlyFeature(): boolean {
    return this.hasSleepParkFlyBundleFeature || this.hasSleepParkFlyRoomOnlyFeature
  }
  get hasSleepParkFlyBundleFeature(): boolean {
    return !!this.features?.sleepParkFlyBundle?.active
  }
  get hasSleepParkFlyRoomOnlyFeature(): boolean {
    return !!this.features?.sleepParkFlyRoomOnly?.active
  }
  async mounted() {
    this.redirectIfDisabled()
    await this.fetchRates()
  }

  // created // ***
  async created() {
    this.redirectIfDisabled()
  }

  // methods // ***
  redirectIfDisabled() {
    if (this.$store.getters['core/featureDisabled']('availability'))
      this.$router.push('/errors/404')
  }

  async getAvailabilityOnCalendarChange(event: any) {
    const viewStart = DateTime.fromISO(event.start.date)
      .startOf('week')
      .minus({ weeks: 1 })
      .toISODate()
    const viewEnd = DateTime.fromISO(event.end.date).endOf('week').plus({ weeks: 1 }).toISODate()
    await this.getAvailability(viewStart, viewEnd)
    await this.fetchBundlePrices(viewStart, viewEnd)
  }
  async refreshAvailability() {
    const { currentCalendarView } = this
    const viewStart = currentCalendarView
      .startOf('month')
      .startOf('week')
      .minus({ weeks: 1 })
      .toISODate()
    const viewEnd = currentCalendarView.endOf('month').endOf('week').plus({ weeks: 1 }).toISODate()
    await this.getAvailability(viewStart, viewEnd)
    await this.fetchBundlePrices(viewStart, viewEnd)
  }
  async getAvailability(fromDate: string, toDate: string) {
    const { data } = await api(
      `supplier/availability?organisationId=${this.organisationId}&fromDate=${fromDate}&toDate=${toDate}`,
      {},
      {}
    )
    if (data) this.existingAvailability = data
  }
  async fetchRates() {
    const ratesUrl = `organisation/GetHotelRateCards/${this.organisationId}`
    const { data } = (await api(ratesUrl, undefined, undefined)) || {}
    if (data) {
      this.rates = data.rates
    } else {
      this.rates = []
    }
  }
  async fetchBundlePrices(fromDate: string, toDate: string) {
    const { data } =
      (await api(
        `supplier/availability/bundlePrices/${this.organisationId}?fromDate=${fromDate}&toDate=${toDate}`,
        undefined,
        undefined
      )) || {}
    if (data) {
      this.bundlePrices = data
    } else {
      this.bundlePrices = []
    }
  }
  getEventColor(event: Record<string, any>): string {
    if (event.start === event.name) return 'red'
    if (event.itemTypeId < 0) {
      if (event.name === 'Closed') return 'grey darken-4'
      switch (event.itemTypeId) {
        case -1:
          return 'green lighten-2'
        case -2:
          return 'blue darken-3'
        case -3:
          return 'deep-orange lighten-1'
        case -4:
          return 'pink darken-4'
      }
    }

    var config = hotelItemTypes.find(x => x.id === event.itemTypeId)
    if (this.getCustomerText(event)) {
      return 'pink'
    }
    return `${config?.colour || 'primary'}${this.isInSelectionMode ? ' lighten-4' : ''}`
  }
  getEventTitle(event: Record<string, any>): string {
    let item = hotelItemTypes.find(x => x.id === event.itemTypeId)
    const roomName = item
      ? this.$tc(`availability.${item.key}${event.numberAvailable === 1 ? `` : `s`}`)
      : 'NOT FOUND'
    return `${event.numberAvailable} x ${roomName}${
      event.numberAvailable > 0 ? ` @ ${event.rate}` : ''
    }`
  }
  getCreatedText(event: Record<string, any>): string {
    if (!event.createdBy || !event.createdDate) {
      return ``
    }
    return (
      this.$tc(`availability.submittedBy`) +
      event.createdBy +
      ` - ` +
      DateTime.fromISO(event.createdDate).toFormat('EEE, dd MMM, yyyy HH:mm:ss')
    )
  }
  getUpdatedText(event: Record<string, any>): string {
    if (!event.updatedBy || !event.updatedDate) {
      return ``
    }
    return (
      this.$tc(`availability.updatedBy`) +
      event.updatedBy +
      ` - ` +
      DateTime.fromISO(event.updatedDate).toFormat('EEE, dd MMM, yyyy HH:mm:ss')
    )
  }
  getCustomerText(event: Record<string, any>): string {
    if (!event.customerName) {
      return ``
    }
    return this.$tc(`availability.specificallyFor`) + event.customerName
  }
  editAvailability({
    nativeEvent,
    event,
  }: {
    nativeEvent: Record<string, any>
    event: Record<string, any>
  }) {
    nativeEvent.stopPropagation()
    if (event.temp) {
      this.dateSelected({ nativeEvent, date: event.start })
      return
    }
    this.editDate = event.start

    if (event.itemTypeId < 0) {
      this.bundlePriceInEdit = this.bundlePrices.find(x => x.date.startsWith(event.start))!

      this.editingBundle = true
    } else this.editing = true
  }
  dateSelected({ date }: { nativeEvent: any; date: string }) {
    this.processDate(date, null)
  }
  processDate(date: string, isToggleOn: boolean | null) {
    const { selectedDates } = this
    const onlyAdd = isToggleOn !== null && isToggleOn
    const onlyRemove = isToggleOn !== null && !isToggleOn
    if (selectedDates.length === 0) {
      selectedDates.push(date)
      return
    }
    if (selectedDates.length === 1) {
      if (selectedDates[0] === date) {
        selectedDates.pop()
        return
      }
      const interval = this.getAllDaysFromDateRange(date, selectedDates[0], null)
      this.selectedDates = [...interval]
      return
    }
    //if exists in range then remove
    let existingIndex = selectedDates.indexOf(date)
    if (existingIndex > -1 && !onlyAdd) {
      selectedDates.splice(existingIndex, 1)
      return
    }
    //if no longer exists in range then add back in
    let firstGreaterThan = selectedDates.findIndex(x => x > date)
    if (firstGreaterThan > 0 && !onlyRemove) {
      selectedDates.splice(firstGreaterThan, 0, date)
      return
    }

    //If this is a toggle, don't try processing dates beyond the original range
    if (isToggleOn !== null) return
    //If before extend range
    if (date < selectedDates[0]) {
      const interval = this.getAllDaysFromDateRange(date, selectedDates[0], null)
      selectedDates.splice(0, 1, ...interval)
    }
    //if after extend range
    if (date > selectedDates[selectedDates.length - 1]) {
      const interval = this.getAllDaysFromDateRange(
        selectedDates[selectedDates.length - 1],
        date,
        null
      )
      selectedDates.splice(-1, 1, ...interval)
    }
  }
  getAllDaysFromDateRange(dateOne: string, dateTwo: string, day: number | null): string[] {
    let startDate = DateTime.fromISO(dateOne)
    let endDate = DateTime.fromISO(dateTwo)
    if (startDate > endDate) {
      let holder = startDate
      startDate = endDate
      endDate = holder
    }
    const days = Interval.fromDateTimes(startDate.startOf('day'), endDate.endOf('day')).splitBy({
      day: 1,
    })
    if (day !== null) {
      return days.filter(x => x.start.weekday % 7 === day).map(d => d.start.toISODate())
    }
    return days.map(d => d.start.toISODate())
  }
  clear() {
    this.selectedDates = []
    this.weekdayToggles = [
      { nameKey: 'sunday', value: true },
      { nameKey: 'monday', value: true },
      { nameKey: 'tuesday', value: true },
      { nameKey: 'wednesday', value: true },
      { nameKey: 'thursday', value: true },
      { nameKey: 'friday', value: true },
      { nameKey: 'saturday', value: true },
    ]
  }
  setToday() {
    this.value = ''
  }
  prev() {
    const refs: Record<string, any> = this.$refs
    refs.calendar.prev()
  }
  next() {
    const refs: Record<string, any> = this.$refs
    refs.calendar.next()
  }
  toggleDay(dayIndex: number) {
    const { selectedDates } = this
    const dayToggle = this.weekdayToggles[dayIndex].value
    const days = this.getAllDaysFromDateRange(
      selectedDates[0],
      selectedDates[selectedDates.length - 1],
      dayIndex
    )
    days.forEach(x => this.processDate(x, dayToggle))
  }

  async submit({
    itemTypes,
    useCmacRates,
    bundleCode,
    roomOnlyCode,
  }: {
    itemTypes: ItemType[]
    useCmacRates: boolean
    bundleCode: string
    roomOnlyCode: string
  }) {
    this.loading = true
    const { selectedDates, availabilityType, organisationId } = this
    let availabilities: any[] = []
    itemTypes
      .filter(x => x.numberAvailable !== null)
      .forEach(val => {
        selectedDates.forEach(date => {
          availabilities.push({
            type: availabilityType,
            itemTypeId: val.id,
            fromDate: date,
            numberAvailable: val.numberAvailable,
            rate: val.rate,
          })
        })
      })
    let payload = {
      organisationId: organisationId,
      useCmacAgreedRates: useCmacRates,
      availabilities: availabilities,
      bundleCode,
      roomOnlyCode,
    }

    await api(`supplier/availability`, { method: 'POST' }, { data: payload })
      .then(() => {
        this.clear()
        this.refreshAvailability()
      })
      .finally(() => {
        this.loading = false
        this.isAdding = false
      })
  }
  async fullyBooked() {
    const payload = {
      organisationId: this.organisationId,
      datesToClear: this.selectedDates,
    }
    await api(`supplier/availability/clearHotelAvailability`, { method: 'POST' }, { data: payload })
      .then(() => {
        this.clear()
        this.refreshAvailability()
      })
      .finally(() => {
        this.loading = false
      })
  }
  async onSubmitEdit(values: any[]) {
    const payload = {
      organisationId: this.organisationId,
      availabilities: values,
    }
    await api(`supplier/availability/bulkupdate`, { method: 'PATCH' }, { data: payload }).then(
      () => {
        this.refreshAvailability()
        this.editing = false
      }
    )
  }

  async onBundleSubmitEdit(evt: any) {
    const payload = {
      organisationId: this.organisationId,
      date: evt.date,
      roomOnlyOpen: evt.roomOnlyOpen,
      bundle4Open: evt.bundle4Open,
      bundle8Open: evt.bundle8Open,
      bundle15Open: evt.bundle15Open,
    }
    await api(`supplier/availability/updatebundles`, { method: 'PATCH' }, { data: payload }).then(
      () => {
        this.refreshAvailability()
        this.editingBundle = false
      }
    )
  }
  onCancel() {
    this.isAdding = false
    this.clear()
  }
  async uploadBundlePrices() {
    if (this.file === null) return
    let formData: FormData = new FormData()
    formData.append('file', this.file)
    formData.append('organisationId', this.organisationId)
    api(
      'supplier/availability/loadbundleprices',
      { data: formData, method: 'POST', formData: true },
      undefined
    )
      .then(() => {
        this.refreshAvailability()
        this.$store.dispatch('core/launchSnackbar', {
          color: 'success',
          message: 'Prices uploaded',
        })
      })
      .catch(e => {
        this.$store.dispatch('core/launchSnackbar', {
          color: 'error',
          message: e,
        })
      })
  }
}
