
import cloneDeep from 'lodash/cloneDeep'
import { DateTime } from 'luxon'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component({})
export default class BundleEditDialog extends Vue {
  //Props
  @Prop({ required: true, type: Object })
  editEvent!: any
  @Prop()
  value!: boolean
  @Prop({ required: false, default: false })
  hasSleepParkFlyBundleFeature!: boolean
  @Prop({ required: false, default: false })
  hasSleepParkFlyRoomOnlyFeature!: boolean

  // data // ***
  loading = false
  event: any = {}
  roomOnlyOpen = false
  bundle4Open = false
  bundle8Open = false
  bundle15Open = false

  roomOnlyLocked = false
  bundle4Locked = false
  bundle8Locked = false
  bundle15Locked = false

  // computed // ***
  get dateInEdit(): string {
    return DateTime.fromISO(this.editEvent.date).toFormat('EEE, dd MMM, yyyy')
  }
  //Watch
  @Watch('value')
  onValueChanged(v: boolean) {
    if (v) this.setEvents()
  }

  setEvents() {
    this.event = cloneDeep(this.editEvent)
    const e = this.event
    this.roomOnlyOpen = e.priceDoubleRoom !== null
    this.bundle4Open = e.priceBundle4 !== null
    this.bundle8Open = e.priceBundle8 !== null
    this.bundle15Open = e.priceBundle15 !== null
    this.roomOnlyLocked = !this.roomOnlyOpen
    this.bundle4Locked = !this.bundle4Open
    this.bundle8Locked = !this.bundle8Open
    this.bundle15Locked = !this.bundle15Open
  }
  close() {
    this.$emit('input', false)
  }
  onCancel() {
    this.close()
  }
  onSubmit() {
    this.$emit('submit', {
      date: this.event.date,
      roomOnlyOpen: this.roomOnlyOpen,
      bundle4Open: this.bundle4Open,
      bundle8Open: this.bundle8Open,
      bundle15Open: this.bundle15Open,
    })
  }
}
